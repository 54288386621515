import { Padding } from "@mui/icons-material";
import { boxShadow } from "html2canvas/dist/types/css/property-descriptors/box-shadow";
import { overflow } from "html2canvas/dist/types/css/property-descriptors/overflow";

const homeStyles = {
    mainStack: {
        // background: 'url("images/home/cover.jpg")',
        // backgroundSize: "cover",
    
    },
    

    mainHeading: {
        ...(window.innerWidth <= 601 && {
            fontSize: '28px'
        })
    },
    card: {
        width: '250px',
        height: 'auto',
        borderRadius:'20px',
        padding:'5px',
        margin:'5px',
        boxShadow:'5px'
    },
    section: {
        background: 'url("images/home/vector1.png")',
        backgroundSize: "cover",
        backgroundRepeat: 'no-repeat',
       
        
    },
    sampleImage: {
       
        width: '80%',
        height: 'auto'
    },
    body: {
        textAlign: 'center !important',
        hyphens: 'auto',
        wordSpacing: '-.05em',
        padding: '0 2rem',
        lineHeight: '1.6',
        margin: '30px',
        
    },
    body1: {
        textAlign: 'center !important',
        hyphens: 'auto',
        wordSpacing: '-.05em',
        padding: '0 2rem',
        lineHeight: '1.6',
        margin: '5px',
        
    },

    mySpan:{
        color:'#d32f2f',
    }
   
}

export default homeStyles;
import React, { lazy } from 'react';
import { Box, Button, Grid, Stack, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import homeStyles from './Home.styles';
const FastForwardIcon = lazy(() => import('@mui/icons-material/FastForward'));
const Header = React.lazy(() => import('../global/header/Header'))
const Footer = React.lazy(() => import('../global/footer/Footer'))

const Home = () => {
    const classes = homeStyles;
    const navigate = useNavigate();

    const makeHeader = (text: string) => {
        return (
            <Typography fontSize='25px' fontWeight='bold' textAlign='center' m={'80px'}>
                {text}
            </Typography>
        )
    }

    const makeBody = (text: string) => {
        return (
            <Typography variant="body2" textAlign={'justify'} align='center' sx={classes.body}>
                {text}
            </Typography>
        )
    }

    const home = () => {
        return (
            <Stack style={classes.mainStack} >
                <Header></Header>
                <Stack paddingTop='2px' style={classes.section}>
                    <Grid container >
                        <Grid padding='5vh 2vh 5vh 2vh' item xs={12} sm={12} md={12} lg={12} xl={12} display='flex' flexDirection='column' justifyContent='center' alignItems='center'>
                            <Typography fontSize='30px' fontWeight='bolder' sx={{textAlign: 'center'}}> Create Your Perfect <span style={classes.mySpan}>Marriage BioData</span> Online</Typography>
                            <Typography fontSize='15px' sx={{textAlign: 'center'}}>Create, Customize & download your biodata in just a few clicks.</Typography>
                            <Typography fontSize='15px' mb={2} sx={{textAlign: 'center'}}>It's quick and easy- no sign-up or registration needed. Try Now!</Typography>
                            <Button variant='contained' color='error' size='large' endIcon={<FastForwardIcon />} onClick={() => navigate('/create-bio-data')}><Typography variant='button'>Create Biodata Now</Typography></Button>
                        </Grid>
                        <Grid item padding='2vh 0 0vh 0'  xs={12} sm={12} md={12} lg={12} xl={12} px={4} display='flex' flexDirection='column' justifyContent='center' alignItems='center'>
                            <img
                                style={classes.sampleImage}
                                srcSet={`/images/home/newBiodata-img.png?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                                src={`/images/home/newBiodata-img.png`}
                                alt={`sample-image`}
                                loading="lazy"
                            />
                        </Grid>
                    </Grid>
                </Stack >
               
                


                <Stack>
                    <Grid container paddingTop='12px'>
                      
                        <Grid padding='5vh 2vh 5vh 2vh' item xs={12} sm={12} md={12} lg={12} xl={12} display='flex' flexDirection='column' justifyContent='center' alignItems='center'>
                            <Typography fontSize='30px' fontWeight='bolder' sx={{textAlign: 'center'}}>Why Choose Our Marriage Biodata Maker</Typography>
                            <Typography fontSize='15px' sx={classes.body1}>In today’s fast-paced digital world, safeguarding your personal information is a top priority. At QuickDocSolutions.com, we’re all about your privacy, our local-only approach ensures your biodata stays right where it belongs, on your device. With our cutting edge algorithm, you have the power to customize your biodata effortlessly, add or remove fields to fit your needs. Plus, sharing your marriage biodata with loved ones is now a breeze send it directly via email without any hassle. Experience the ultimate in secure, stylish, and seamless biodata creation today!</Typography>
                        </Grid>
                      
                    </Grid>
                </Stack>

                <Stack>
                <Grid container padding='4vh 0 8vh 0'>
                       
                       <Grid item xs={12} sm={12} md={4} lg={4} xl={4} display='flex' flexDirection='column' justifyContent='center' alignItems='center'>
                        <img src="/images/home/icon1.svg" alt="" />
                        <Typography fontSize='20px' fontWeight='bolder' sx={{textAlign: 'center',paddingTop:'15px'}}>No Data stored</Typography>
                        <p style={{textAlign:'center',padding:'0 10px', margin:'0 10px 32px 0'}}>Do you worry about privacy? Because we do all of the work locally, your biodata information never leaves your device.</p>
                       </Grid>
                       <Grid item xs={12} sm={12} md={4} lg={4} xl={4} display='flex' flexDirection='column' justifyContent='center' alignItems='center'>
                        <img src="/images/home/icon2.svg" alt="" />
                        <Typography fontSize='20px' fontWeight='bolder' sx={{textAlign: 'center',paddingTop:'15px'}}>Customize</Typography>
                        <p style={{textAlign:'center' ,padding:'0 10px', margin:'0 10px 32px 0'}}>Want more? To make your biodata more appealing , you can include additional fields,colors,font styles and images.</p>
                       </Grid>
                       <Grid item xs={12} sm={12} md={4} lg={4} xl={4} display='flex' flexDirection='column' justifyContent='center' alignItems='center'>
                        <img src="/images/home/icon3.svg" alt="" />
                        <Typography fontSize='20px' fontWeight='bolder' sx={{textAlign: 'center' , paddingTop:'15px'}}>Email</Typography>
                        <p style={{textAlign:'center',padding:' 10px', margin:'0 10px 32px 0'}}>Do you need to quickly share biodata? Enter a legitimate Email ID and get your biodata message in a flash.</p>
                       </Grid>
                       
                    </Grid>
                </Stack>

              <Stack style={{backgroundImage:'linear-Gradient(rgb(151, 47, 47),rgb(182, 34, 31))', color:'white'}}>
              <Stack padding='7vh 2vh 5vh 2vh'>
                <Typography fontSize='30px' fontWeight='bolder' sx={{textAlign: 'center'}}>Steps to Create and Download Marriage Biodata</Typography>

                
                </Stack>
                <Stack>
                <Grid container paddingTop='12px'>
                       <Grid item xs={12} sm={12} md={4} lg={4} xl={4} display='flex' flexDirection='column' justifyContent='center' alignItems='center'>
                       <img 
                                  style={classes.card}
                                srcSet={`${'images/home/frameStep1.png'}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                                src={`${'images/home/frameStep1.png'}?w=161&fit=crop&auto=format`}
                                alt={'No Data Saved'}
                                loading="lazy"
                            />
                       </Grid>
                       <Grid item xs={12} sm={12} md={4} lg={4} xl={4} display='flex' flexDirection='column' justifyContent='center' alignItems='center'>
                       <img 
                                  style={classes.card}
                                srcSet={`${'images/home/frameStep2.png'}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                                src={`${'images/home/frameStep2.png'}?w=161&fit=crop&auto=format`}
                                alt={'No Data Saved'}
                                loading="lazy"
                            />
                       </Grid>
                       <Grid item xs={12} sm={12} md={4} lg={4} xl={4} display='flex' flexDirection='column' justifyContent='center' alignItems='center'>
                       <img 
                                  style={classes.card}
                                srcSet={`${'images/home/frameStep3.png'}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                                src={`${'images/home/frameStep3.png'}?w=161&fit=crop&auto=format`}
                                alt={'No Data Saved'}
                                loading="lazy"
                            />
                       </Grid>
                       {makeBody("Making a marriage biodata that looks good is the initial step towards discovering the right life mate. But are you looking for a biodata format, or are you not sure how to construct a marital biodata? If so, you've arrived at the ideal location. Using a desktop, laptop, or smartphone, QuickDocSolution.com makes it simple for you to prepare the best marriage biodata online. These days, creating a biodata is really easy. After entering the necessary data and choosing the template, download the biodata as a PDF file. Click the checkboxes next to the fields you wish to fill out, then input the required data to complete the Personal Details section. If you need to make any changes to a field, just click the edit symbol. Just to recap.")}

                    </Grid>
                </Stack>
                </Stack>  
             


                <Footer></Footer>
            </Stack>
        )
    }

    return home();
}

export default Home;
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  alpha,
  Box,
  Button,
  FormControl,
  Grid,
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  styled,
  Switch,
  SwitchProps,
  Tab,
  Tabs,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import React, { useState } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import { Field, SubField } from "../../../global/Types";
import LogoPicker from "./logoPicker/LogoPicker";
import formStyles from "./Form.styles";
import { red } from "@mui/material/colors";
import { countLines } from "../../../global/helper";
import EditIcon from "@mui/icons-material/Edit";

const dateTimePickerWidth = 220;

const commonInputStyle = {
  width: "95%",
  border: `2px solid #5cb35c`,  // Apply consistent green border
  borderRadius: "4px",
  paddingLeft: "4px",
  paddingRight: "4px",
  height: "45px",  // Define a common height
  boxSizing: "border-box",  // Ensures padding doesn't affect height
  "& .MuiOutlinedInput-notchedOutline": {
    border: "none"  // Remove the default input border
  },
  "&:hover .MuiOutlinedInput-notchedOutline": {
    border: "none"  // Remove border on hover
  },
  "& .MuiInputBase-input": {
    border: "none"  // Ensure no internal border for input text
  },
  "& .MuiInputAdornment-root": {
    height: "100%",  // Align icon vertically
    display: "flex",
    alignItems: "center"
  },
  "& .MuiSvgIcon-root": {
    height: "1.5em",  // Set icon size
    width: "1.5em"
  }
};




const RedSwitch = styled(Switch)(({ theme }) => ({
  "& .MuiSwitch-switchBase.Mui-checked": {
    color: red[400],
    "&:hover": {
      backgroundColor: alpha(red[400], theme.palette.action.hoverOpacity),
    },
  },
  "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
    backgroundColor: red[400],
  },
}));

const label = { inputProps: { "aria-label": "Color switch demo" } };

const ariaFieldLabel = {
  "aria-label": "description",
  style: { color: "white" },
  maxLength: 28,
};

const ariaSubFieldLabel = {
  "aria-label": "description",
  style: { color: "black" },
  maxLength: 14,
};

const Form = (props: any) => {
  const classes = formStyles;

  let timeoutId: any;
  const colors = ["#5cb35c", "#896aae", "#ffb242"];
  const [expanded, setExpanded] = React.useState<string | false>("panel0");
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [isOverflowing, setIsOverflowing] = useState(false);

  const [image, setImage] = useState(null);
  const [previewUrl, setPreviewUrl] = useState("");

  const handleSelectLogo = (logo: any) => {
    props.setFormData({
      ...props.formData,
      logo: { ...logo, visibility: true },
    });
  };

  const muiColorByColorHash = (colorHash: string) => {
    switch (colorHash) {
      case "#5cb35c":
        return "success";
      case "#896aae":
        return "secondary";
      case "#ffb242":
        return "warning";
      default:
        return undefined;
    }
  };

  const handleSubFieldKeyChange = (
    event: any,
    subField: SubField,
    fieldId: number
  ) => {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => {
      let fieldArray = [...props.formData.fields];
      let currentSubFieldJson = fieldArray.filter((e) => e.id === fieldId)[0]
        .subfield;
      currentSubFieldJson.forEach((element: any) => {
        if (element.id === subField.id) {
          element.key = event.target.value;
        }
      });
      let fieldIndex = props.formData.fields.findIndex(
        (obj: any) => obj.id === fieldId
      );
      if (fieldIndex !== -1) {
        fieldArray[fieldIndex].subfield = currentSubFieldJson;
        props.setFormData({ ...props.formData, fields: fieldArray });
      }
    }, 50);
  };

  const handleSubFieldValueChange = (
    event: any,
    subField: SubField,
    fieldId: number
  ) => {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => {
      let fieldArray = [...props.formData.fields];
      let currentSubFieldJson = fieldArray.filter((e) => e.id === fieldId)[0]
        .subfield;
      currentSubFieldJson.forEach((element: any) => {
        if (element.id === subField.id) {
          element.value = event.target.value;
        }
      });
      let fieldIndex = props.formData.fields.findIndex(
        (obj: any) => obj.id === fieldId
      );
      if (fieldIndex !== -1) {
        fieldArray[fieldIndex].subfield = currentSubFieldJson;
        props.setFormData({ ...props.formData, fields: fieldArray });
      }
    }, 50);
  };

  const handleSwitchChange = (
    event: any,
    subField: SubField,
    fieldId: number
  ) => {
    let fieldArray = [...props.formData.fields];
    let currentSubFieldJson = fieldArray.filter((e) => e.id === fieldId)[0]
      .subfield;
    currentSubFieldJson.forEach((element: any) => {
      if (element.id === subField.id) {
        element.visibility = event.target.checked;
      }
    });
    let fieldIndex = props.formData.fields.findIndex(
      (obj: any) => obj.id === fieldId
    );
    if (fieldIndex !== -1) {
      fieldArray[fieldIndex].subfield = currentSubFieldJson;
      props.setFormData({ ...props.formData, fields: fieldArray });
    }
  };

  const createSubField = (
    subField: SubField,
    fieldId: number,
    hashColor: string
  ) => {
    return (
      <Grid container>
        <Grid item xs={12} sm={5} md={5} lg={6} xl={5}>
          <Switch
            onChange={(e) => handleSwitchChange(e, subField, fieldId)}
            {...label}
            checked={subField.visibility} // Use checked prop instead of defaultChecked
            color={muiColorByColorHash(hashColor)}
          />
          <Input
            onChange={(e) => handleSubFieldKeyChange(e, subField, fieldId)}
            disableUnderline={true}
            defaultValue={subField.key}
            color="secondary"
            
            inputProps={ariaSubFieldLabel}
          />
        </Grid>
        <Grid item xs={12} sm={7} md={7} lg={6} xl={7}>
          {subField?.type === "dropdown" ? (
            <FormControl fullWidth key={subField.id}>
              <InputLabel>{subField.name}</InputLabel>
              <Select
                value={subField.value}
                label={`Please Enter ${subField.key}.`}
             
                sx={commonInputStyle} 
                onChange={(event: any) =>
                  handleSubFieldChange(event, fieldId, subField.id)
                }
              >
                
                {subField.options?.map((option: any) => (
                  <MenuItem key={option.id} value={option.value}>
                    {option.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          ) : subField?.type === "calendar" ? (
            <TextField
              label={subField.name}
              type="date"
              placeholder={`Please Enter ${subField.key}.`}
              sx={commonInputStyle} 
              defaultValue={subField.value}
              onChange={(e) => handleSubFieldValueChange(e, subField, fieldId)}
              InputLabelProps={{ shrink: true }}
              // sx={{ width: "95%", borderRadius: "4px", paddingRight: "4px" }}
            />
          ) : subField?.type === "clock" ? (
            <TextField
              label={subField.name}
              type="time"
              placeholder={`Please Enter ${subField.key}.`}
              sx={commonInputStyle} 
              defaultValue={subField.value}
              onChange={(e) => handleSubFieldValueChange(e, subField, fieldId)}
              InputLabelProps={{ shrink: true }}
              // sx={{ width: "95%", borderRadius: "4px", paddingRight: "4px" }}
            />
          ) : (
            <Input
              defaultValue={subField.value}
              onChange={(e) => handleSubFieldValueChange(e, subField, fieldId)}
              disableUnderline={true}
              sx={{
                width: "95%",
                border: `2px solid ${hashColor}`,
                borderRadius: "4px",
                paddingLeft: "4px",
                paddingRight: "4px",
              }}
              color={muiColorByColorHash(hashColor)}
              placeholder={`Please Enter ${subField.key}.`}
            />
          )}
        </Grid>
      </Grid>
    );
  };

  const handleDeleteField = (idToRemove: number) => {
    props.setFormData({
      ...props.formData,
      fields: props.formData.fields.filter(
        (item: Field) => item.id !== idToRemove
      ),
    });
  };

  const handleDeleteSubField = (idToRemove: number, fieldId: number) => {
    let fieldArray = [...props.formData.fields];
    let filteredSubField: any[] = [];
    let filteredFieldJson = props.formData.fields.map((field: any) => {
      if (field.id === fieldId) {
        filteredSubField = field.subfield.filter(
          (e: any) => e.id !== idToRemove
        );
      }
    });
    let fieldIndex = props.formData.fields.findIndex(
      (obj: any) => obj.id === fieldId
    );
    if (fieldIndex !== -1) {
      fieldArray[fieldIndex].subfield = filteredSubField;
      props.setFormData({ ...props.formData, fields: fieldArray });
    }
  };

  const handleFieldNameChange = (event: any, idToUpdate: number) => {
    clearTimeout(timeoutId);

    timeoutId = setTimeout(() => {
      const updatedArray = props.formData.fields.map((item: any) => {
        if (item.id === idToUpdate) {
          return { ...item, name: event.target.value };
        }
        return item;
      });
      props.setFormData({ ...props.formData, fields: updatedArray });
    }, 50);
  };

  const deleteFieldButtonVisibility = (id: number) => {
    return (
      id === (props.formData.fields[props.formData.fields.length - 1]?.id ?? 1)
    );
  };

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  const handleSubFieldChange = (
    event: React.ChangeEvent<{ value: unknown }>,
    fieldId: number,
    subFieldId: number
  ) => {
    const selectedValue = event.target.value;

    props.setFormData((prevFormData: any) => {
      const updatedFields = prevFormData.fields.map((field: any) => {
        if (field.id === fieldId) {
          const updatedSubfields = field.subfield.map((subfield: any) =>
            subfield.id === subFieldId
              ? { ...subfield, value: selectedValue }
              : subfield
          );
          return { ...field, subfield: updatedSubfields };
        }
        return field;
      });

      return { ...prevFormData, fields: updatedFields };
    });
  };

  const createField = (field: Field, backgroundColor: string) => {
    return (
      <>
        <Accordion
        sx={{ marginBottom: 2 }}
          expanded={expanded === "panel" + field.id}
          onChange={handleChange("panel" + field.id)}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            sx={getAccordionSummaryStyles(backgroundColor)}
          >
            {deleteFieldButtonVisibility(field.id) && (
              <IconButton
                aria-label="delete"
                size="medium"
                onClick={() => handleDeleteField(field.id)}
              >
                <DeleteIcon fontSize="inherit" />
              </IconButton>
            )}
            <Input
              disableUnderline={true}
              defaultValue={field.name}
              color="secondary"
              inputProps={ariaFieldLabel}
              onChange={(e) => handleFieldNameChange(e, field.id)}
              startAdornment={
                <InputAdornment position="start">
                  <EditIcon />
                </InputAdornment>
              }
            />
          </AccordionSummary>
          <AccordionDetails sx={classes.acordionDetails}>
            {field.subfield.map((e) =>
              createSubField(e, field.id, backgroundColor)
            )}

            <Grid p={2}>
              <Button
                variant="contained"
                color={muiColorByColorHash(backgroundColor)}
                onClick={() => handleAddSubField(field.id)}
              >
                + Add More Field
              </Button>
            </Grid>
          </AccordionDetails>
        </Accordion>
      </>
    );
  };

  const handleAddField = () => {
    if (countLines(props.formData.fields) >= 39) {
      console.log("maximum limit reached!");
    } else {
      props.setFormData({
        ...props.formData,
        fields: [
          ...props.formData.fields,
          {
            id:
              props.formData.fields.length === 0
                ? 0
                : props.formData.fields[props.formData.fields.length - 1]?.id +
                  1,
            name: "New Section",
            subfield: [
              { id: 0, key: "New Field", value: "", visibility: true },
            ],
          },
        ],
      });
    }
  };

  const handleAddSubField = (fieldId: number) => {
    if (
      (fieldId === 1 && props.formData.fields[1].subfield.length >= 15) ||
      countLines(props.formData.fields) >= 40
    ) {
      console.log("maximun limit reached");
      //need to add error popup
    } else {
      let fieldArray = [...props.formData.fields];
      let currentSubFieldJson = fieldArray.filter((e) => e.id === fieldId)[0]
        .subfield;
      let fieldIndex = props.formData.fields.findIndex(
        (obj: any) => obj.id === fieldId
      );
      if (fieldIndex !== -1) {
        fieldArray[fieldIndex].subfield = [
          ...currentSubFieldJson,
          {
            id:
              currentSubFieldJson.length === 0
                ? 0
                : currentSubFieldJson[currentSubFieldJson.length - 1]?.id + 1,
            key: "New Field",
            value: "",
            visibility: true,
          },
        ];
        props.setFormData({ ...props.formData, fields: fieldArray });
      }
    }
  };

  const navButton = () => {
    return (
      <>
        <Grid container spacing={2} p={1}>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Button
              variant="outlined"
              color={muiColorByColorHash(
                getBackgroundColor(props?.fields?.length)
              )}
              onClick={handleAddField}
            >
              Add New Section
            </Button>
          </Grid>
        </Grid>
      </>
    );
  };

  const getBackgroundColor = (index: number) => {
    return colors[index % colors.length];
  };

  const logoSelector = () => {
    return (
      <Grid container>
        <Grid item xs={7} sm={7} md={7} lg={7} xl={7}>
          <Typography
            style={{ cursor: "pointer", paddingTop: "9px", color: "#FE6B8B" }}
            onClick={handleOpen}
          >
            Choose Logo
          </Typography>
          <LogoPicker
            open={open}
            onClose={handleClose}
            onSelect={handleSelectLogo}
          />
        </Grid>
        <Grid item xs={5} sm={5} md={5} lg={5} xl={5}>
          {props?.formData?.logo && (
            <div>
              <img
                src={props.formData.logo.src}
                alt={props.formData.logo.alt}
                style={{ width: 50, height: 50 }}
              />
            </div>
          )}
        </Grid>
      </Grid>
    );
  };

  const handleFileChange = (event: any) => {
    const file = event.target.files[0];
    if (file) {
      setImage(file);
      // Create a preview URL for the selected image
      const previewUrl = URL.createObjectURL(file);
      // setPreviewUrl(previewUrl);
      props.setFormData({
        ...props.formData,
        primaryPhoto: { ...props.formData.primaryPhoto, value: previewUrl },
      });
    }
  };

  const primaryPhotoSelector = () => {
    return (
      <Grid container>
        <Grid item xs={7} sm={7} md={7} lg={7} xl={7}>
          <div style={{ paddingTop: "10px" }}>
            <label
              style={{ cursor: "pointer", paddingTop: "9px", color: "#FE6B8B" }}
            >
              Upload Photo
              <input
                type="file"
                id="file-input"
                accept="image/*"
                onChange={handleFileChange}
                style={{ display: "none" }} // Hide the default input
              />
            </label>
          </div>
        </Grid>
        {/* <Grid item xs={5} sm={5} md={5} lg={5} xl={5}>
        {props?.formData?.logo && (
          <div>
            <img src={props.formData.logo.src} alt={props.formData.logo.alt} style={{ width: 50, height: 50 }} />
          </div>
        )}
      </Grid> */}
      </Grid>
    );
  };

  const handleAdditionalFileChange = (event: any) => {
    const file = event.target.files[0];
    if (file) {
      setImage(file);
      // Create a preview URL for the selected image
      const previewUrl = URL.createObjectURL(file);
      // setPreviewUrl(previewUrl);
      props.setFormData({
        ...props.formData,
        multiplePhoto: {
          ...props.formData.multiplePhoto,
          value: [...props.formData.multiplePhoto.value, previewUrl],
        },
      });
    }
  };

  const AdditionalPhotoSelector = () => {
    return (
      <Grid container>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <div style={{ paddingTop: "10px" }}>
            <label
              style={{ cursor: "pointer", paddingTop: "9px", color: "#FE6B8B" }}
            >
              Upload Multiple Photos
              <input
                type="file"
                id="file-input"
                accept="image/*"
                onChange={handleAdditionalFileChange}
                style={{ display: "none" }} // Hide the default input
              />
            </label>
          </div>
        </Grid>
      </Grid>
    );
  };

  const getAccordionSummaryStyles = (color: string) => {
    return {
      background: `linear-gradient(45deg, ${color} 30%, #faebeb 90%)`,
      color: "white",
      "&:hover": {
        background: `linear-gradient(45deg, ${color} 30%, #faebeb 90%)`,
      },
      borderRadius: "4px",
      height: "40px",
      minHeight: "40px",
      padding: "0 16px",
    };
  };

  const formHeader = () => {
    return (
      <>
        <Accordion
      sx={{ marginBottom: 2 }}
          expanded={expanded === "panel" + 0}
          onChange={handleChange("panel" + 0)}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            sx={getAccordionSummaryStyles("#FE6B8B")}
          >
            <Input
              disableUnderline={true}
              defaultValue={"Header"}
              color="secondary"
              inputProps={ariaFieldLabel}
              startAdornment={
                <InputAdornment position="start">
                  <EditIcon />
                </InputAdornment>
              }
            />
          </AccordionSummary>
          <AccordionDetails
            sx={classes.acordionDetails}
            style={{ justifyContent: "space-evenly", flexDirection: "row" }}
          >
            <Box>
              <Grid className="form-topHeader-grid" container>
                <Grid item xs={3.5} sm={3.5} md={3.5} lg={3.5} xl={3.5}>
                  <RedSwitch
                    checked={props.formData.topHeader.visibility} // Use checked instead of defaultChecked
                    onChange={(e) =>
                      props.setFormData({
                        ...props.formData,
                        topHeader: {
                          ...props.formData.topHeader,
                          visibility: e.target.checked,
                        },
                      })
                    }
                    {...label}
                    color={muiColorByColorHash("#5cb35c")}
                  />
                </Grid>
                <Grid item xs={8.5} sm={8.5} md={8.5} lg={8.5} xl={8.5}>
                  <Input
                    defaultValue={"|| Shree Ganesh ||"}
                    onChange={(e) =>
                      props.setFormData({
                        ...props.formData,
                        topHeader: {
                          ...props.formData.topHeader,
                          value: e.target.value,
                        },
                      })
                    }
                    disableUnderline={true}
                    sx={{
                      color: "red",
                      width: "95%",
                      border: `2px solid ${"#FE6B8B"}`,
                      borderRadius: "4px",
                      paddingLeft: "4px",
                      paddingRight: "4px",
                    }}
                    color={"primary"}
                    placeholder={`Type here...`}
                  />
                </Grid>
              </Grid>
              <Grid className="form-logo-grid" container>
                <Grid item xs={3.5} sm={3.5} md={3.5} lg={3.5} xl={3.5}>
                  <RedSwitch
                    checked={props.formData.logo.visibility}
                    onChange={(e) =>
                      props.setFormData({
                        ...props.formData,
                        logo: {
                          ...props.formData.logo,
                          visibility: e.target.checked,
                        },
                      })
                    }
                    {...label}
                    defaultChecked
                    color={muiColorByColorHash("#5cb35c")}
                  />
                </Grid>
                <Grid item xs={8.5} sm={8.5} md={8.5} lg={8.5} xl={8.5}>
                  {logoSelector()}
                </Grid>
              </Grid>

              <Grid className="form-bottomHeader-grid" container>
                <Grid item xs={3.5} sm={3.5} md={3.5} lg={3.5} xl={3.5}>
                  <RedSwitch
                    checked={props.formData.bottomHeader.visibility}
                    onChange={(e) =>
                      props.setFormData({
                        ...props.formData,
                        bottomHeader: {
                          ...props.formData.bottomHeader,
                          visibility: e.target.checked,
                        },
                      })
                    }
                    {...label}
                    defaultChecked
                    color={muiColorByColorHash("#5cb35c")}
                  />
                </Grid>
                <Grid item xs={8.5} sm={8.5} md={8.5} lg={8.5} xl={8.5}>
                  <Input
                    defaultValue={"BIO DATA"}
                    onChange={(e) =>
                      props.setFormData({
                        ...props.formData,
                        bottomHeader: {
                          ...props.formData.bottomHeader,
                          value: e.target.value,
                        },
                      })
                    }
                    disableUnderline={true}
                    sx={{
                      color: "red",
                      width: "95%",
                      border: `2px solid ${"#FE6B8B"}`,
                      borderRadius: "4px",
                      paddingLeft: "4px",
                      paddingRight: "4px",
                    }}
                    color={"primary"}
                    placeholder={`Type here...`}
                  />
                </Grid>
              </Grid>

              <Grid className="primary-image-grid" container>
                <Grid item xs={3.5} sm={3.5} md={3.5} lg={3.5} xl={3.5}>
                  <RedSwitch
                    checked={props.formData.primaryPhoto.visibility}
                    onChange={(e) =>
                      props.setFormData({
                        ...props.formData,
                        primaryPhoto: {
                          ...props.formData.primaryPhoto,
                          visibility: e.target.checked,
                        },
                      })
                    }
                    {...label}
                    defaultChecked
                    color={muiColorByColorHash("#5cb35c")}
                  />
                </Grid>
                <Grid item xs={8.5} sm={8.5} md={8.5} lg={8.5} xl={8.5}>
                  {primaryPhotoSelector()}
                </Grid>
              </Grid>

              <Grid className="primary-image-grid" container>
                <Grid item xs={3.5} sm={3.5} md={3.5} lg={3.5} xl={3.5}>
                  <RedSwitch
                    checked={props.formData.multiplePhoto.visibility}
                    onChange={(e) =>
                      props.setFormData({
                        ...props.formData,
                        multiplePhoto: {
                          ...props.formData.multiplePhoto,
                          visibility: e.target.checked,
                        },
                      })
                    }
                    {...label}
                    defaultChecked
                    color={muiColorByColorHash("#5cb35c")}
                  />
                </Grid>
                <Grid item xs={8.5} sm={8.5} md={8.5} lg={8.5} xl={8.5}>
                  {AdditionalPhotoSelector()}
                </Grid>
              </Grid>
            </Box>
          </AccordionDetails>
        </Accordion>
      </>
    );
  };

  return (
    <div>
      {formHeader()}
      {props.formData.fields.map((e: any, index: number) =>
        createField(e, getBackgroundColor(index))
      )}
      {navButton()}
    </div>
  );
};

export default Form;

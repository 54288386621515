import { display } from "html2canvas/dist/types/css/property-descriptors/display";

const headerStyles = {
    header: {
        padding: '12px 16px',
        boxShadow: '2px 2px 5px rgba(0, 0, 0, 0.5)',
        cursor: 'pointer',
        
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        backgroundColor: '#f5f5f5', // Example background color
        borderBottom: '1px solid #ddd', // Example border style
    },
    hyperlink: {
        color: 'black',
        textDecoration: 'none',
        fontWeight: 'bold',
        fontSize: '22px',
        fontFamily: 'Roboto',
    },
    typography: {
        fontSize: '22px',
        fontWeight: 'bold'
    }
}

export default headerStyles;
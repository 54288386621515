const viewStyles = {
    mainContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-around',
    },
    mainPaper: {
        border: "2px solid black",
        paddingTop: '35px',
        boxSizing: 'border-box',
        overflow: 'auto',
        backgroundSize: 'cover',
    },
    mainBox: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'stretch',
        flexWrap: 'nowrap',
        background: 'red'
    },
    heading: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        Height: '460px',
    },
    photo: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        paddingTop: '10px',
        paddingBottom: '10px'
    },
}

export default viewStyles;

import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import calculatedPricePopupStyles from "./calculatedPricePopup.styles";
import CustomButton from "../manglikStatus/customButton/CustomButton";
import { BioDataFormData } from "../../../global/Types";

interface calculatedPricePopupProps {
  open: boolean;
  onClose: any;
  handlePayAndDownloadButton: any;
  formData: BioDataFormData;
  loadingBtn: any;
}

const CalculatedPricePopup = ({
  open,
  onClose,
  handlePayAndDownloadButton,
  formData,
  loadingBtn,
}: calculatedPricePopupProps) => {
  const classes = calculatedPricePopupStyles;
  const totalPayable = formData.frame.price;
  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle sx={classes.dialogTitle}>Calculated Price</DialogTitle>
      <DialogContent>
        <div style={classes.content}>
          <h2 style={classes.header}>Price Details</h2>
          <p style={classes.text}>
            <strong>Frame Price:</strong> &#8377;{formData.frame.price}
          </p>
          <hr style={classes.separator} />
          <p style={classes.text}>
            <strong>Total Payable Amount:</strong> &#8377;{totalPayable}
          </p>
        </div>
      </DialogContent>
      <DialogContent style={{ display: "flex", justifyContent: "center" }}>
        {/* <CustomButton onClick={() => handlePayAndDownloadButton(totalPayable)} color="primary" variant="contained" style={{ width: '200px' }}> */}
        <CustomButton
          onClick={() => !loadingBtn && handlePayAndDownloadButton(0)}
          color="primary"
          variant="contained"
          className={`w-52 ${
            loadingBtn ? "pointer-events-none opacity-50" : "hover:bg-blue-700"
          }`}
        >
          {loadingBtn
            ? "Processing"
            : totalPayable === 0
            ? "Free Download"
            : "Pay and Download"}
        </CustomButton>
      </DialogContent>
      <DialogActions style={classes.actions}>
        <Button
          onClick={onClose}
          color="secondary"
          variant="contained"
          sx={classes.closeButton}
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CalculatedPricePopup;
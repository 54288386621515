import viewStyles from "./View.styles";
import { Box, Grid, Paper, Typography, Button, Stack } from "@mui/material";
import { Field, SubField } from "../../../global/Types";
import { useEffect, useRef, useState } from "react";
import styled from "styled-components";
// @ts-ignore
import * as htmlToImage from "html-to-image";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";

const StyledTypography = styled(Typography)(({ theme }) => ({
  maxWidth: "75%",
  overflowWrap: "break-word",
  wordBreak: "break-word",
  whiteSpace: "normal",
  lineHeight: "2.5",
  overflow: "hidden",
}));

const View = ({
  paddingTop,
  secondPagepaddingTop,
  photoHeight,
  fieldSize,
  subFieldSize,
  formData,
  className,
  height,
  width,
}: any) => {
  const classes = viewStyles;
  const contentRef = useRef<HTMLDivElement | null>(null); // Ref to track content size
  const [pages, setPages] = useState<Field[][]>([]); // State to store paginated content

  const fontStyle = {
    fontFamily: formData.font.fontFamily,
    fontStyle: formData.font.fontStyle,
    fontWeight: formData.font.fontWeight,
  };

  useEffect(() => {
    if (contentRef.current) {
      paginateContent(); // Call pagination with the latest formData
    }
  }, [formData]);

  useEffect(() => {
    if (contentRef.current) {
      const contentHeight = contentRef.current.scrollHeight;
      const boxHeight = height;
      if (contentHeight > boxHeight) {
        paginateContent();
      } else {
        setPages([formData?.fields]);
      }
    }
  }, [formData]);

  const paginateContent = () => {
    const paginated: Field[][] = [];
    let currentPage: Field[] = [];
    let currentHeight = 0;
    const buffer = 80; // Add buffer to allow for spacing

    formData?.fields.forEach((field: Field, index: number) => {
      const fieldHeight = calculateFieldHeight(field, index);

      // Calculate height including additional subfield height
      const subfieldsHeight = field.subfield.length * 5;

      if (currentHeight + fieldHeight + subfieldsHeight > height - buffer) {
        if (currentPage.length > 0) {
          paginated.push(currentPage); // Add current page content
        }
        currentPage = [field]; // Start new page with the current field
        currentHeight = fieldHeight + subfieldsHeight;
      } else {
        currentPage.push(field);
        currentHeight += fieldHeight + subfieldsHeight;
      }
    });

    if (currentPage.length > 0) {
      paginated.push(currentPage); // Add last page if any content remains
    }

    setPages(paginated);
  };

  const calculateFieldHeight = (field: Field, index: number) => {
    // Create a temporary div to render the field content and calculate its height
    const tempDiv = document.createElement("div");
    tempDiv.style.visibility = "hidden";
    tempDiv.style.position = "absolute";
    tempDiv.style.width = `${width - 100}px`; // Subtract padding/margin for accurate width

    // Set the content inside tempDiv for height measurement
    tempDiv.innerHTML = `
      <div style="font-weight: bold; font-size: ${fieldSize}px;">${
      field.name
    }</div>
      ${field.subfield
        .map(
          (s: SubField) =>
            `<div style="font-size: ${subFieldSize}px;">
              <span>${s.key}</span>: <span>${s.value || ""}</span>
            </div>`
        )
        .join("")}
    `;

    document.body.appendChild(tempDiv); // Temporarily add to the DOM to measure
    const height = tempDiv.scrollHeight; // Measure the height
    document.body.removeChild(tempDiv); // Remove it after measuring

    return height;
  };

  const renderPage = (pageData: Field[], pageIndex: number) => {
    const additionalHeight = pageData.reduce(
      (acc, field) => acc + field.subfield.length * 10,
      0
    );
    let addedHeight = `${height + additionalHeight}px`;
    console.log("addedHeight", addedHeight);
    return (
      <Box
        key={pageIndex}
        style={{
          backgroundImage: `url("/images/frames/${formData.frame.name}")`,
          backgroundSize: `${width}px ${height + additionalHeight}px`,
          backgroundRepeat: `repeat-y`,
          width: `${width}px`,
          height: `${height + additionalHeight}px`,
          marginTop: "30px",
        }}
      >
      <div className="ml-4" >
      <Box
          sx={{
            ...classes.heading,
            paddingTop: pageIndex !== 0 ? "60px" : paddingTop,
          }}
        >
          {pageIndex === 0 && formData?.topHeader?.visibility && (
            <Typography fontSize={subFieldSize}>
              {formData.topHeader.value}
            </Typography>
          )}
          {pageIndex === 0 && formData?.logo.visibility && (
            <img
              src={formData.logo.src}
              alt={formData.logo.alt}
              style={{ width: 30, height: 30 }}
            />
          )}
          {pageIndex === 0 && formData?.bottomHeader?.visibility && (
            <Typography>{formData.bottomHeader.value}</Typography>
          )}
        </Box>
        <Box sx={{ ...classes.photo }}>
          {pageIndex === 0 &&
            formData?.primaryPhoto?.value &&
            formData?.primaryPhoto?.visibility && (
              <img
                src={formData?.primaryPhoto?.value}
                alt={formData.logo.alt}
                style={{
                  width: "auto",
                  height: photoHeight,
                  border: "1px solid black",
                  borderRadius: "100px",
                }}
              />
            )}
        </Box>
        <Box className="ml-10">
          {pageData.map((field: Field, outerIndex: number) => (
            <div key={outerIndex}>
              <Typography
                fontSize={fieldSize}
                className="title"
                style={{ ...fontStyle, fontWeight: "bolder" }}
              >
                {field.name}
              </Typography>
              {field.subfield.map((s: SubField, innerIndex: number) => {
                if (s.visibility) {
                  return (
                    <Grid container key={innerIndex}>
                      <Grid item xs={5}>
                        <StyledTypography
                          style={fontStyle}
                          fontSize={subFieldSize}
                        >
                          {s.key}
                        </StyledTypography>
                      </Grid>
                      <Grid item xs={0.5}>
                        {s.value && (
                          <StyledTypography
                            style={fontStyle}
                            fontSize={subFieldSize}
                          >
                            :
                          </StyledTypography>
                        )}
                      </Grid>
                      <Grid item xs={6.5}>
                        <StyledTypography
                          style={fontStyle}
                          fontSize={subFieldSize}
                        >
                          {s.value}
                        </StyledTypography>
                      </Grid>
                    </Grid>
                  );
                }
                return null;
              })}
            </div>
          ))}
          {/* <div
          className="flex justify-center items-center pt-4 mr-4"
          >
            <div>
              {formData?.multiplePhoto?.visibility &&
                formData?.multiplePhoto?.value.map((e: any) => {
                  return (
                    <img
                      style={{
                        margin: "1px",
                        width: "auto",
                        height: photoHeight,
                        border: "1px solid black",
                        borderRadius: "6px",
                      }}
                      src={e}
                      alt="Preview"
                    />
                  );
                })}
            </div>
          </div> */}

          {pageIndex !== 0 &&
            formData.multiplePhoto.value.length > 0 &&
            formData.multiplePhoto.visibility && (
              <Stack className={className + 3} justifyContent="center">
                <Stack
                  display="flex"
                  flexDirection="row"
                  flexWrap="wrap"
                  justifyContent="center"
                >
                  {formData?.multiplePhoto?.visibility &&
                    formData?.multiplePhoto?.value.map((e: any) => {
                      return (
                        <img
                          style={{
                            margin: "1px",
                            width: "auto",
                            height: photoHeight,
                            border: "1px solid black",
                            borderRadius: "6px",
                          }}
                          src={e}
                          alt="Preview"
                        />
                      );
                      // return <img src={e} alt={formData.logo.alt} style={{ margin: '10px', width: 'auto', height: '104px', border: '1px solid black', borderRadius: '6px' }} />
                    })}
                </Stack>
              </Stack>
            )}
        </Box>
      </div>
      </Box>
    );
  };

  return (
    <div
      className="overflow-y-scroll max-h-screen p-4"
      ref={contentRef}
    >
      <Paper id="divToPrint" elevation={12}>
        {pages.map((page, index) => renderPage(page, index))}
      </Paper>
    </div>
  );
};

export default View;

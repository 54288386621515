
// const localhost = "http://localhost:6010";
const production = "https://backend.quickdocsolution.com";


const baseURL = production;

export const urls = {

    checkManglikStatus: `${baseURL}/manglik/status`,
    searchBirthCityDetail: `${baseURL}/manglik/location/city`,
    generateDownloadBiodataLink: `${baseURL}/bio/data/generate/link`,
    downloadBiodataByDocumentId: `${baseURL}/bio/data/download/id`

}
const dummyRealFormData = {
  logo: {
    id: 1,
    src: "/images/god-icon/1.png",
    alt: "Logo 1",
    visibility: true,
  },
  frame: { name: "", price: 0 },
  font: { id: 1, fontFamily: "Times", fontStyle: "", fontWeight: "" },
  topHeader: { value: "|| Shree Ganesh ||", visibility: true },
  bottomHeader: { value: "BIO DATA", visibility: true },
  primaryPhoto: { value: "/images/dummy/photo.jpg", visibility: true },
  multiplePhoto: {
    value: [
      "/images/dummy/photo.jpg",
      "/images/dummy/photo.jpg",
      "/images/dummy/photo.jpg",
      "/images/dummy/photo.jpg",
      "/images/dummy/photo.jpg",
    ],
    visibility: true,
  },
  fields: [
    // {
    //   id: 1,
    //   name: "Personal Details",
    //   subfield: [
    //     {
    //       id: 0,
    //       key: "Name",
    //       value: "Ravi Kumar Sharma",
    //       visibility: true,
    //     },
    //     {
    //       id: 3,
    //       key: "Place of Birth",
    //       value: "New Delhi",
    //       visibility: true,
    //     },
    //     {
    //       id: 2,
    //       key: "Dates of Birth",
    //       value: "5 Jan 1995",
    //       visibility: true,
    //     },

    //     {
    //       id: 4,
    //       key: "Rashi",
    //       value: "Tula (Libra)",
    //       visibility: true,
    //     },
    //     {
    //       id: 5,
    //       key: "Nakshatra",
    //       value: "Rohini",
    //       visibility: true,
    //     },
    //     {
    //       id: 6,
    //       key: "Complexion",
    //       value: "Very Fair",
    //       visibility: true,
    //     },
    //     {
    //       id: 7,
    //       key: "Height",
    //       value: "5 feet 5 inches",
    //       visibility: true,
    //     },
    //     {
    //       id: 8,
    //       key: "Education",
    //       value: "Bachelor''s",
    //       visibility: true,
    //     },
    //     {
    //       id: 9,
    //       key: "Occupation",
    //       value: "Software Developer",
    //       visibility: true,
    //     },
    //   ],
    // },
    // {
    //   id: 2,
    //   name: "Family Details",
    //   subfield: [
    //     {
    //       id: 0,
    //       key: "Father Name",
    //       value: "Ram Prem Sharma",
    //       visibility: true,
    //     },
    //     {
    //       id: 1,
    //       key: "Father Occupation",
    //       value: "Electrician",
    //       visibility: true,
    //     },
    //     {
    //       id: 2,
    //       key: "Mother Name",
    //       value: "Pinki Sharma",
    //       visibility: true,
    //     },
    //     {
    //       id: 3,
    //       key: "Mother's Occupation",
    //       value: "Homemaker",
    //       visibility: true,
    //     },
    //   ],
    // },
    // {
    //   id: 3,
    //   name: "Contact Details",
    //   subfield: [
    //     {
    //       id: 0,
    //       key: "Contact Number",
    //       value: "+91-8051693666",
    //       visibility: true,
    //     },
    //     {
    //       id: 1,
    //       key: "Address",
    //       value: "Sec-3, Gautam Buddha Nagar, Noida, 140407",
    //       visibility: true,
    //     },
    //   ],
    // },
  ],
};

const dummyBlankFormData = {
  logo: {
    id: 1001,
    src: "/images/god-icon/1.png",
    alt: "Logo 1",
    visibility: true,
  },
  frame: { name: "watermark.jpg", price: 0 },
  font: { id: 1002, fontFamily: "", fontStyle: "", fontWeight: "" },
  topHeader: { value: "|| Shree Ganesh ||", visibility: true },
  bottomHeader: { value: "BIO DATA", visibility: true },
  primaryPhoto: { value: "", visibility: true },
  multiplePhoto: { value: [], visibility: true },
  fields: [
    {
      id: 2001,
      name: "Personal Details",
      subfield: [
        { id: 3001, key: "Name", value: "", visibility: true },
        { id: 3002, key: "Place of Birth", value: "", visibility: true },
        { id: 3003, type: "calendar", key: "Birth Date", value: "", visibility: true },
        { id: 3004, type: "clock", key: "Time of Birth", value: "", visibility: true },
        {
          id: 3005,
          key: "Manglik Dosha",
          value: "",
          visibility: true,
          type: "dropdown",
          options: [
            { id: 4001, key: "Yes", value: "Yes", name: "Yes" },
            { id: 4002, key: "No", value: "No", name: "No" },
            { id: 4003, key: "Angshik", value: "Angshik", name: "Angshik" }
          ]
        },
        {
          id: 3006,
          key: "Height",
          value: "",
          visibility: true,
          type: "dropdown",
          options: [
            { id: 4004, key: "Foot", value: "Foot", name: "Foot" },
            { id: 4005, key: "Inches", value: "Inches", name: "Inches" }
          ]
        },
        { id: 3007, key: "Religion", value: "", visibility: false },
        { id: 3008, key: "Caste", value: "", visibility: true },
        { id: 3009, key: "Highest Education", value: "", visibility: true },
        { id: 3010, key: "Job/Occupation", value: "", visibility: true },
        { id: 3011, key: "Current Location", value: "", visibility: true },
        { id: 3012, key: "Languages Spoken", value: "", visibility: false },
        { id: 3013, key: "Annual Compensation", value: "", visibility: false },
        { id: 3014, key: "Nakshatra", value: "", visibility: false },
        {
          id: 3015,
          key: "Rashi/Zodiac",
          value: "",
          visibility: false,
          type: "dropdown",
          options: [
            { id: 4006, key: "Mesh(Aries)", value: "Mesh(Aries)", name: "Mesh(Aries)" },
            { id: 4007, key: "Vrishabha (Taurus)", value: "Vrishabha (Taurus)", name: "Vrishabha (Taurus)" },
            { id: 4008, key: "Mithuna (Gemini)", value: "Mithuna (Gemini)", name: "Mithuna (Gemini)" },
            { id: 4009, key: "Karka (Cancer)", value: "Karka (Cancer)", name: "Karka (Cancer)" },
            { id: 4010, key: "Simha (Leo)", value: "Simha (Leo)", name: "Simha (Leo)" },
            { id: 4011, key: "Kanya (Virgo)", value: "Kanya (Virgo)", name: "Kanya (Virgo)" },
            { id: 4012, key: "Tula(Libra)", value: "Tula(Libra)", name: "Tula(Libra)" },
            { id: 4013, key: "Vrischika (Scorpio)", value: "Vrischika (Scorpio)", name: "Vrischika (Scorpio)" },
            { id: 4014, key: "Dhanur (Sagittarious)", value: "Dhanur (Sagittarious)", name: "Dhanur (Sagittarious)" },
            { id: 4015, key: "Makara (Capricorn)", value: "Makara (Capricorn)", name: "Makara (Capricorn)" },
            { id: 4016, key: "Kumbha (Aquarius)", value: "Kumbha (Aquarius)", name: "Kumbha (Aquarius)" },
            { id: 4017, key: "Meena (Pisces)", value: "Meena (Pisces)", name: "Meena (Pisces)" }
          ]
        },
        {
          id: 3016,
          key: "Complexion",
          value: "",
          visibility: false,
          type: "dropdown",
          options: [
            { id: 4018, key: "Very Fair", value: "Very Fair", name: "Very Fair" },
            { id: 4019, key: "Fair", value: "Fair", name: "Fair" },
            { id: 4020, key: "Medium", value: "Medium", name: "Medium" },
            { id: 4021, key: "Brown", value: "Brown", name: "Brown" },
            { id: 4022, key: "Dark", value: "Dark", name: "Dark" }
          ]
        },
        {
          id: 3017,
          key: "Is Horoscope Match a must",
          value: "",
          visibility: false,
          type: "dropdown",
          options: [
            { id: 4023, key: "Yes", value: "Yes", name: "Yes" },
            { id: 4024, key: "No", value: "No", name: "No" },
            { id: 4025, key: "Some Aspects", value: "Some Aspects", name: "Some Aspects" }
          ]
        },
        {
          id: 3018,
          key: "Food Habits",
          value: "",
          visibility: false,
          type: "dropdown",
          options: [
            { id: 4026, key: "Vegetarian", value: "Vegetarian", name: "Vegetarian" },
            { id: 4027, key: "Non-Vegetarian", value: "Non-Vegetarian", name: "Non-Vegetarian" },
            { id: 4028, key: "Eggetarian", value: "Eggetarian", name: "Eggetarian" },
            { id: 4029, key: "Vegan", value: "Vegan", name: "Vegan" }
          ]
        }
      ]
    },
    {
      id: 2002,
      name: "Family Details",
      subfield: [
        { id: 5001, key: "Mother’s Name", value: "", visibility: true },
        { id: 5002, key: "Mother’s Occupation", value: "", visibility: true },
        { id: 5003, key: "Father’s Name", value: "", visibility: true },
        { id: 5004, key: "Father’s Occupation", value: "", visibility: true },
        { id: 5005, key: "Number of Siblings", value: "", visibility: true },
        { id: 5006, key: "Brother’s Name", value: "", visibility: false },
        { id: 5007, key: "Sister’s Name", value: "", visibility: false },
        {
          id: 5008,
          key: "Family Type",
          value: "",
          visibility: false,
          type: "dropdown",
          options: [
            { id: 6001, key: "Nuclear", value: "Nuclear", name: "Nuclear" },
            { id: 6002, key: "Extended", value: "Extended", name: "Extended" }
          ]
        },
        { id: 5009, key: "Parents Current Location", value: "", visibility: false },
        { id: 5010, key: "Hometown", value: "", visibility: false }
      ]
    },
    {
      id: 2003,
      name: "Contact Details",
      subfield: [
        { id: 7001, key: "Contact Number", value: "", visibility: true },
        { id: 7002, key: "Address", value: "", visibility: true },
        { id: 7003, key: "Alternate Contact Number", value: "", visibility: false },
        { id: 7004, key: "Preferred Time to Contact", value: "", visibility: true }
      ]
    }
  ]
};

export { dummyRealFormData, dummyBlankFormData };

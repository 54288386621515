import { Box, Button, Grid, Stack, Tab, Tabs, Typography } from "@mui/material";
// import { useState } from 'react'
import Form from "./components/form/Form";
import { BioDataFormData, FontType } from "../global/Types";
import View from "./components/view/View";
import Frame from "./components/frame/Frame";
import Font from "./components/font/Font";
import { dummyBlankFormData } from "../DummyData";
import bioDataMakerStyles from "./BioDataMakerStyles";
import { useNavigate } from "react-router-dom";
import { generateDownloadBiodataLink } from "./bioDataMakerService";
import CalculatedPricePopup from "./components/calculatedPricePopup/CalculatedPricePopup";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import { useRef, useState } from "react";

function TabPanel(props: any) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const BioDataMaker = () => {
  const contentRef = useRef<HTMLDivElement | null>(null); // Ref to track content size
  const [loadingBtn, setloadingBtn] = useState(false);
  const classes = bioDataMakerStyles;
  const navigate = useNavigate();
  const [tabValue, setTabValue] = useState(0);
  const [bioDataFormData, setFormData] = useState<any>({
    ...dummyBlankFormData,
  });
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [price, setPrice] = useState(0);

  const handleCloseDialog = () => {
    setIsDialogOpen(false);
  };

  const handleChange = (event: any, newValue: any) => {
    setTabValue(newValue);
  };

  const handleFrameChange = (selectedFrame: any) => {
    setFormData({
      ...bioDataFormData,
      frame: {
        ...bioDataFormData.frame,
        name: selectedFrame.name,
        price: selectedFrame.price,
      },
    });
  };

  const handleFontChange = (font: FontType) => {
    setFormData({ ...bioDataFormData, font: font });
  };

  const tabs = () => {
    return (
      <Grid container sx={classes.header}>
        <Grid
  item
  xs={12}
  sm={12}
  md={4}
  lg={4}
  xl={4}
  p={2}
  style={{ cursor: "pointer" }}
  onClick={() => navigate("/")}
>
  <Stack direction="row">
    <Typography style={classes.typography}>Quick</Typography>
    <Typography style={classes.typography} color="tomato">
      Doc
    </Typography>
    <Typography style={classes.typography}>Solutions</Typography>
    <Typography style={classes.typography}>.com</Typography>
  </Stack>
</Grid>
        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
          <Tabs
            value={tabValue}
            onChange={handleChange}
            aria-label="tabs example"
          >
            <Tab label="Details" id="tab-0" aria-controls="tabpanel-0" />
            <Tab label="Frame" id="tab-1" aria-controls="tabpanel-1" />
            <Tab label="Font" id="tab-2" aria-controls="tabpanel-2" />
          </Tabs>
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
          <Typography
            onClick={() =>
              window.open("/manglik-status", "_blank", "noopener,noreferrer")
            }
            style={{
              margin: "0 16px",
              fontWeight: 600,
              color: "purple",
              cursor: "pointer",
            }}
          >
            Check Manglik Status
          </Typography>
        </Grid>
      </Grid>
    );
  };

  const view = () => {
    return (
      <>
        <Stack
          className="viewCotainer "
          display="flex"
          flexDirection="row"
          justifyContent="center"
        >
          <View
            paddingTop="38px"
            photoHeight="80px"
            fieldSize="12px"
            subFieldSize="10px"
            formData={bioDataFormData}
            height={665}
            width={466}
          ></View>
        </Stack>
        <div
          style={{
            position: "absolute",
            left: "-9999px",
          }}
        >
          <View
            paddingTop="90px"
            secondPagepaddingTop="90px"
            photoHeight="200px"
            fieldSize="30px"
            subFieldSize="25px"
            className="bio-data-page"
            formData={bioDataFormData}
            height={1122}
            width={793}
          ></View>
        </div>
        <div>
          <Button
            sx={classes.downloadButton}
            onClick={() => setIsDialogOpen(true)}
          >
            Download PDF
          </Button>
        </div>
      </>
    );
  };

  const downloadBioData = async (totalPayable: number) => {
    setloadingBtn(true);
    try {
      const element = document.getElementById("divToPrint");
      if (!element) return;

      const a4Width = 210; // A4 width in mm
      const a4Height = 300; // A4 height in mm
      const padding = 0; // Adjust padding for more space

      const pdf = new jsPDF({
        orientation: "portrait",
        unit: "mm",
        format: "a4",
        compress: true,
      });

      // Apply CSS for line-height and font size dynamically to ensure it is applied in the PDF generation
      const allTypographyElements = element.querySelectorAll(
        "p, span, div, h1, h2, h3, h4, h5, h6"
      );
      const pages = Array.from(element.children);

      // Save the original styles
      const originalStyles: { lineHeight: string; fontSize: string }[] = [];
      allTypographyElements.forEach((el, index) => {
        const htmlElement = el as HTMLElement;
        originalStyles[index] = {
          lineHeight: htmlElement.style.lineHeight,
          fontSize: htmlElement.style.fontSize,
        };
        htmlElement.style.lineHeight = "2.6"; // Adjusted line-height
        htmlElement.style.fontSize = "10px"; // Decrease font size for PDF
      });

      for (let index = 0; index < pages.length; index++) {
        const page = pages[index];
        if (page instanceof HTMLElement) {
          const canvas = await html2canvas(page, { scale: 3 });
          const imgData = canvas.toDataURL("image/png");

          const imgWidth = a4Width - 2 * padding;
          const imgHeight = (a4Width / canvas.width) * canvas.height;
          const adjustedImgHeight = Math.min(imgHeight, a4Height - 2 * padding);

          pdf.addImage(
            imgData,
            "PNG",
            padding,
            padding,
            imgWidth,
            adjustedImgHeight
          );

          if (index < pages.length - 1) {
            pdf.addPage();
          } else {
            if (totalPayable === 0) {
              pdf.save("document.pdf");
              setloadingBtn(false);
            } else {
              setloadingBtn(false);

              const pdfBlob = pdf.output("blob");

              const formData = new FormData();
              formData.append("file", pdfBlob, "bio-data.pdf");
              formData.append("amount", totalPayable.toString());

              const generatedBioDataDownloadLink =
                await generateDownloadBiodataLink(formData);
              window.location.href = generatedBioDataDownloadLink.longurl;
            }
          }
        }
      }

      // Reset the styles to original values
      allTypographyElements.forEach((el, index) => {
        const htmlElement = el as HTMLElement;
        htmlElement.style.lineHeight = originalStyles[index].lineHeight || ""; // Restore line-height
        htmlElement.style.fontSize = originalStyles[index].fontSize || ""; // Restore font size
      });
    } catch (error) {
      console.error("Error occurred while downloading the PDF:", error);
    }
  };

  return (
    <Grid container>
      <Grid className="fill-form" item xs={12} sm={12} md={12} lg={12} xl={12}>
        {tabs()}
      </Grid>
      <Grid
        item
        xs={12}
        sm={12}
        md={6}
        lg={6}
        xl={6}
        style={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        <TabPanel value={tabValue} index={0}>
          <Form formData={bioDataFormData} setFormData={setFormData}></Form>
        </TabPanel>
        <TabPanel value={tabValue} index={1}>
          <Frame handleFrameChange={handleFrameChange}></Frame>
        </TabPanel>
        <TabPanel value={tabValue} index={2}>
          <Font handleFontChange={handleFontChange}></Font>
        </TabPanel>
      </Grid>
      <Grid
        item
        xs={12}
        sm={12}
        md={6}
        lg={6}
        xl={6}
        style={{
          display: "flex",
          justifyContent: "space-around",
          flexDirection: "column",
        }}
      >
        {view()}
      </Grid>
      <CalculatedPricePopup
        open={isDialogOpen}

        loadingBtn={loadingBtn}
        onClose={handleCloseDialog}
        handlePayAndDownloadButton={downloadBioData}
        formData={bioDataFormData}
      />
    </Grid>
  );
};

export default BioDataMaker;
